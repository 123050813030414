import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { CTAButton, CTAButtonWrapper } from './styles/CTAButton';
import useCTAVisibility from './useCTAVisibility';

const HiddeableHeaderCTA = ({
  hiddenHeight,
  btnLabel,
  styleVariant,
  buttonColor,
  hasBlur,
}) => {
  const isVisible = useCTAVisibility(hiddenHeight);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (isVisible && (
  <CTAButtonWrapper hasBlur={hasBlur}>
    <CTAButton
      extended
      variant="contained"
      css={css`
            background: ${buttonColor};
          `}
      styleVariant={styleVariant}
      onClick={handleClick}
      intlKey={btnLabel}
    />
  </CTAButtonWrapper>
  )
  );
};

HiddeableHeaderCTA.propTypes = {
  btnLabel: PropTypes.string,
  buttonColor: PropTypes.string,
  hasBlur: PropTypes.bool,
  hiddenHeight: PropTypes.shape({
    lg: PropTypes.number.isRequired,
    md: PropTypes.number.isRequired,
    sm: PropTypes.number.isRequired,
    xl: PropTypes.number.isRequired,
    xs: PropTypes.number.isRequired,
  }).isRequired,
  styleVariant: PropTypes.string,
};

HiddeableHeaderCTA.defaultProps = {
  btnLabel: 'HEADER.MENU.CTA_BUTTON.LABEL',
  styleVariant: 'primary',
  buttonColor: 'primary',
  hasBlur: false,
};

export default HiddeableHeaderCTA;
